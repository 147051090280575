import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import {
  FRONTEND_STORAGE_KEYS,
  useGetStorageValueQuery,
  useSetStorageValueMutation,
} from "@js/apps/common/frontend-storage";

import { openEmployerWelcomeModal } from "../../components/employer-welcome-modal";

export const WELCOME_MODAL_PARAM = "welcome_modal";

type UseEmployerWelcomeModalProps = {
  firstName?: string;
};

export const useEmployerWelcomeModal = ({
  firstName,
}: UseEmployerWelcomeModalProps) => {
  const { isWelcomeModalParam, deleteParam } = useWelcomeModalParam();

  const {
    data: hasWelcomeModalIntroduced,
    isLoading: isLoadingWelcomeModalIntroduced,
  } = useGetStorageValueQuery(
    { key: FRONTEND_STORAGE_KEYS.EMPLOYER_WELCOME_SCREEN_INTRODUCED },
    { skip: !isWelcomeModalParam },
  );
  const [setStorageValue] = useSetStorageValueMutation();

  const shouldOpenModal =
    !hasWelcomeModalIntroduced &&
    !isLoadingWelcomeModalIntroduced &&
    isWelcomeModalParam;

  const dismissWelcomeModal = useCallback(() => {
    setStorageValue({
      key: FRONTEND_STORAGE_KEYS.EMPLOYER_WELCOME_SCREEN_INTRODUCED,
      value: true,
    });
  }, [setStorageValue]);

  useEffect(() => {
    if (!shouldOpenModal) return;

    openEmployerWelcomeModal({
      onClose: () => {
        dismissWelcomeModal();
        deleteParam();
      },
      firstName: firstName || "",
    });
  }, [shouldOpenModal, dismissWelcomeModal, deleteParam, firstName]);
};

export const useWelcomeModalParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isWelcomeModalParam = !!searchParams.get(WELCOME_MODAL_PARAM);

  const deleteParam = useCallback(() => {
    setSearchParams(
      (prevState) => {
        prevState.delete(WELCOME_MODAL_PARAM);
        return prevState;
      },
      { replace: true },
    );
  }, [setSearchParams]);

  return { isWelcomeModalParam, deleteParam };
};

export const useSetWelcomeModalParam = () => {
  const [, setSearchParams] = useSearchParams();

  const setSearchParam = () => {
    setSearchParams((prev) => {
      prev.set(WELCOME_MODAL_PARAM, "true");
      return prev;
    });
  };

  return setSearchParam;
};
