import { Item } from "./item";

import styles from "./styles.module.scss";

export const Interview = () => {
  return (
    <Item>
      <Item.GreenBox>
        <Item.Title text="3. Interview while you sleep" />
        <img
          src={`${SETTINGS.STATIC_URL}employer/interview.png`}
          alt=""
          className={styles.interview}
          width="819px"
          height="685px"
        />
      </Item.GreenBox>
      <Item.Description header="AI Video Screening">
        Our AI conducts and grades video interviews, providing you with a
        detailed scorecard to make fast, informed hiring decisions.
      </Item.Description>
    </Item>
  );
};
