import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import {
  FRONTEND_STORAGE_KEYS,
  useGetStorageValueQuery,
} from "@js/apps/common/frontend-storage";
import { useAccountType } from "@js/apps/common/hooks";
import { WELCOME_MODAL_PARAM } from "@js/apps/employer/hooks/use-employer-welcome-modal";
import { globalConfig } from "@js/constants";
import type { UnverifiedUser } from "@js/types/auth";
import { iframePostMessage, isInIframe } from "@js/utils";

const getRedirectPathForUnfinishedUser = (
  query_params: UnverifiedUser["query_params"],
  showWelcomeModal: boolean,
) => {
  if (query_params?.post_id) {
    return `/feed/${query_params.post_id}/`;
  } else if (query_params?.space_id) {
    return `/spaces/${query_params.space_id}/`;
  } else if (showWelcomeModal) {
    return `/?${WELCOME_MODAL_PARAM}=true`;
  }
  return "/";
};

export const useRedirectAfterSignUp = (unfinishedUser?: UnverifiedUser) => {
  const { isEmployer } = useAccountType();
  const navigate = useNavigate();

  const {
    data: hasWelcomeModalIntroduced,
    isLoading: isLoadingWelcomeModalIntroduced,
  } = useGetStorageValueQuery(
    { key: FRONTEND_STORAGE_KEYS.EMPLOYER_WELCOME_SCREEN_INTRODUCED },
    { skip: !isEmployer },
  );

  const showWelcomeModal =
    !hasWelcomeModalIntroduced && !isLoadingWelcomeModalIntroduced;

  const redirect = useCallback(() => {
    if (!unfinishedUser) return;

    const redirectPath = getRedirectPathForUnfinishedUser(
      unfinishedUser?.query_params,
      showWelcomeModal,
    );

    if (isInIframe()) {
      globalConfig.surpressDisplayGenericRequestError = true;
      iframePostMessage({ redirect: window.location.origin });
    } else {
      navigate(redirectPath);
    }
  }, [navigate, unfinishedUser, showWelcomeModal]);

  return redirect;
};
